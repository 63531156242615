import { ALLink, ALButton } from "../../ALComponents";
import * as Styles from "./CancellationConfirmationPage/styles.module.scss";

function CancellationConfirmationPage() {
  return (
    <div className={Styles.container}>
      <h3 className="h3 h3--bold m-0">
        Thank you for your feedback! Your membership has been cancelled.
      </h3>
      <p className="subtext m-0">Please check your email for cancellation confirmation.</p>

      <div className={Styles.actions}>
        <ALLink to="/collections/bestsellers">
          <ALButton variant="primary" size="large">
            Shop Best Sellers
          </ALButton>
        </ALLink>

        <ALLink to="/account">
          <ALButton variant="secondary" size="large">
            Back to my account
          </ALButton>
        </ALLink>
      </div>
    </div>
  );
}

export default CancellationConfirmationPage;
