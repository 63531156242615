import SEO from "../../../components/seo";
import LuxeCancellationConfirmationPage from "../../../components/luxe/membershipDetails/CancellationConfirmationPage";

function LuxeCancelConfirm() {
  return (
    <>
      <SEO title="Ana Luisa Luxe Cancellation" description="TODO: Add LUXE description" />
      <LuxeCancellationConfirmationPage />
    </>
  );
}

export default LuxeCancelConfirm;
